$coachmark-left-margin: $spacing-xl;

.popover {
  border-radius: 6px;
  background-color: rgba($walla-soft, 0.5);
  border: none;
  font-size: $font-size-xs;
  line-height: $line-height-m;
  z-index: 99 !important;
  font-family: $wallie;

  &.popover-right {
    margin-left: $spacing-xxl;
  }

  .arrow {
    display: none;
  }

  .popover-body {
    width: 220px;
    display: flex;
    padding: $spacing-m;
    color: $blue-grey-2;

    tsl-svg-icon {
      @include square(26px);

      margin-right: $spacing-ms;
      display: inline-table;
      margin-top: -$spacing-xs;
      margin-left: -$spacing-xs;
    }

    div {
      width: 100%;
    }
  }
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
