@import 'style/common';

@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/buttons';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/dropdown';
@import 'bootstrap/scss/tooltip';
@import 'bootstrap/scss/popover';
@import 'bootstrap/scss/badge';
@import 'bootstrap/scss/carousel';

@import './animations';
@import './components/components';
@import '@wallapop/web-styleguide/legacy/fonts';

html {
  height: 100%;
}

html,
body {
  font-size: $font-size-base-walla;

  &.blocked-page {
    overflow: hidden;
  }
}

body {
  font-family: $wallie, Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;

  & > iframe {
    display: none;
  }

  &.route-loading {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($blue-grey-3, 0.5);
      z-index: 200;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      z-index: 201;
      border-radius: 20px;
      background: url('/assets/images/spinner.gif') no-repeat center center / 45px;
      background-color: $white;
    }
  }
}

:focus {
  outline: none;
}

::-webkit-scrollbar {
  width: $scrollbars-width;
  height: $scrollbars-width;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(54 71 79 / 30%);
  outline: 0;
  outline-offset: -2px;
  border: 0;
  border-radius: $border-radius;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(54 71 79 / 50%);
}

.toast {
  display: block !important;
}

// TODO: This class should be removed
.page-content {
  min-height: 100%;

  > .container {
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }
}

button,
.btn {
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

#right-panel {
  .card {
    background-color: $white;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: $wallie, Helvetica;
}

.page-profile .popover {
  display: none;
}

.modal-transparent .modal-dialog .modal-content {
  background-color: transparent;
  box-shadow: none;
}

nav {
  a:hover {
    text-decoration: none;
  }
}
