@import '@wallapop/web-styleguide/legacy/colors';

/* REPLACE BOOTSTRAP VARIABLES */

$grid-gutter-width-base: 20px;
$brand-primary: $walla-main;
$primary: $walla-main;
$brand-success: $positive-main;
$brand-info: $softblue-main;
$brand-warning: $yellow-dark;
$brand-danger: $negative-dark;
$color-grey-lighter: $blue-grey-5;
$color-grey-light: $blue-grey-4;
$color-grey-dark: $blue-grey-3;
$link-color: $walla-main;
$border-radius: 10px;
$border-radius-m: 16px;
$button-border-radius: 25px;
$font-size-base: 1rem; // 16px

/* TAB VARIABLES */
$tab-active-border-width: 2px;

/* Category Navigation */
$category-navigation-height: 48px;

/* Item cards */
$item-card-hover-box-shadow: 0 2px 10px 0 rgba($blue-grey-1, 0.1);
$item-card-box-shadow: 0 0 2px 0 rgba($black, 0.1);
$item-card-hover-translate: translateY(-2px);

/* Credit card */
$credit-card-height: 151px;

/* Sticky Banner */
$sticky-banner-box-shadow: 5px 10px 18px rgba($black, 0.2);

/* Search page variables */
$search-sidebar-ads-width: 300px;
$search-sidebar-filters-width: 265px;
$search-sidebar-scroll-bar-width: 6px;
$top-ad-height: 250px;
$top-ad-width: 980px;

@import 'colors';
@import 'z-index';
