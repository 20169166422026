.carousel {
  .carousel-item {
    flex-direction: column;
    height: 477px;

    img {
      object-fit: cover;
      @include square(100%);

      border-radius: 16px;
    }
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    border-radius: 36px;
    background-color: rgb(255 255 255 / 80%);
    background-repeat: no-repeat;
    background-position: 9px 7px;
    background-image: url('/assets/icons/carousel/arrow-left.svg');
    position: relative;
    width: 24px;
    height: 24px;
    background-size: auto;
  }

  .carousel-control-next-icon {
    transform: rotate(180deg) !important;
  }

  .carousel-indicators {
    align-items: center;

    li {
      @include square(8px);

      max-width: 8px;
      border-radius: 50%;
      background-color: #aeaeae;
      flex-shrink: 0;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;

      &.active {
        @include square(10px);

        max-width: 10px;
        background-color: $white;
      }

      &:hover {
        background-color: $white;
      }
    }
  }
}
