.dropdown,
.dropup {
  .dropdown-toggle {
    cursor: pointer;

    &::after {
      display: none !important;
    }
  }

  .dropdown-menu {
    line-height: normal;
    border-radius: $border-radius;
    box-shadow: 0 4px 10px 0 rgb(0 0 0 / 7%);
    padding: $spacing-ms 0;
    margin-top: $spacing-xs;

    .dropdown-item {
      padding: $spacing-ms $spacing-ml;
      cursor: pointer;

      &:hover {
        color: $blue-grey-1;
      }
    }
  }
}
