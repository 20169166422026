@mixin box() {
  background-color: $white;
  border-radius: 10px;
  border: solid 1px $blue-grey-5;
  margin-bottom: $spacing-ml;
}

@mixin upload-section-styles {
  background-color: $chds-color-content-onhigh;
  border-radius: $chds-size-16;
  border: 1px solid $chds-color-background-mid;
  display: flex;
  flex-direction: column;
  gap: $chds-size-12;
  padding: $chds-size-24 $chds-size-16 $chds-size-24 $chds-size-16;
  margin-bottom: $chds-size-16;
}

@mixin upload-section-title-styles {
  @include chds-typo-mid-l;

  color: $chds-color-content-high;
  font-weight: 700;
}

@mixin upload-section-subtitle-styles {
  @include chds-typo-low-s;

  color: $chds-color-content-mid;
  font-weight: 400;
}
