.archived-overlay {
  background: $walla-main;
  position: absolute;
  inset: 0;
  transform: translateX(100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  .label {
    opacity: 0;
    color: $white;

    .check {
      @include square(30px);

      border: 1px solid $white;
      border-radius: 50%;
      line-height: $line-height-l;
      text-align: center;
      display: inline-block;
      margin-right: $spacing-s;

      tsl-svg-icon {
        height: 10px;
        width: 19px;

        ::ng-deep g g {
          fill: $white;
        }
      }
    }
  }
}

.archived {
  overflow: hidden;

  .archived-overlay {
    transform: translateX(0);
    transition: transform 0.5s;

    .label {
      opacity: 1;
      transition: opacity 0.5s 0.1s;
    }
  }

  .archivable {
    transform: translateX(-10%);
    transition: transform 0.3s;
  }
}
