$border: solid 1px $blue-grey-5;

.box {
  background-color: $white;
  border-radius: $border-radius;
  border: $border;
  margin-bottom: $spacing-ml;

  .box-content {
    padding: $spacing-xl $spacing-xxl;
  }

  .box-section {
    padding: 0 0 $spacing-xl;
    border-bottom: $border;
  }

  .box-header {
    border-bottom: $border;
    padding-top: 0;

    h1 {
      font-weight: $font-weight-chunky;
      color: $blue-grey-2;
      font-size: $font-size-ll;
      letter-spacing: 0.6px;
    }
  }
}
