$modal-standard-width: 620px;
$modal-box-shadow: 0 4px 10px 0 rgb(36 49 56 / 15%);
$modal-border-radius: 0.3rem;

.modal-backdrop.in {
  opacity: 0.7;
}

.modal-backdrop.show {
  opacity: 0.85;
}

.modal {
  display: flex !important;
  justify-content: center;
  flex-direction: column;
}

.modal-content {
  max-width: $modal-standard-width;
  margin: 0 auto;
  box-shadow: $modal-box-shadow;
  border: 0;
  background-color: $white;

  h2,
  p {
    color: $blue-grey-1;
  }

  .modal-close {
    @include square(30px);

    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    text-align: left;

    tsl-svg-icon {
      height: 15px;
      line-height: $line-height-xl;

      path {
        fill: $blue-grey-4;
      }
    }

    &:hover {
      tsl-svg-icon path {
        fill: $blue-grey-1 !important;
      }
    }

    &.light {
      tsl-svg-icon path {
        fill: $white;
        opacity: 0.5;
      }
    }

    &.grey {
      tsl-svg-icon path {
        fill: $blue-grey-4;
      }
    }
  }

  .modal-back {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 99;
  }

  .center-heading {
    text-align: center;

    h2 {
      font-size: $font-size-l;
      color: $blue-grey-1;
    }
  }

  .modal-body,
  .modal-footer {
    padding: $spacing-ml;
  }

  .modal-footer {
    padding-top: 0;
    border-top: 0;

    &.centered {
      text-align: center;
    }
  }

  h2 {
    font-weight: $font-weight-chunky;
    font-size: $font-size-m;
    margin-bottom: 0;
    line-height: $line-height-ml;
  }

  p {
    margin-top: $spacing-m;
    margin-bottom: 0;
    font-size: $font-size-s;
  }

  .btn:not(.btn-primary, .btn-secondary, .btn-feature, .btn-gray, .custom-radio, .btn-text, .simple.light, .btn-link) {
    color: $blue-grey-1;
    background-color: transparent;
    outline: 0;
    border-left: 1px solid $blue-grey-4;
    padding: 0 $spacing-ml;
    border-radius: 0;

    &:first-child {
      border-left: 0;
    }

    &.btn-warn {
      color: $negative-main;
    }

    &.btn-danger {
      background-color: $warn !important;
      color: $white !important;

      &:hover {
        background-color: $warn !important;
        opacity: 0.9;
      }
    }
  }

  .btn-corporate {
    background-color: $walla-main;
    color: $white;
    line-height: $line-height-xxl;
    min-width: 140px;
    transition: background-color 0.25s ease;

    &:hover {
      background-color: darken($walla-main, 5%);
    }
  }
}

.modal.report {
  $modal-width: 480px;

  .modal-dialog {
    max-width: $modal-width;
  }

  .modal-content {
    width: $modal-width !important;
    max-width: unset;
    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
  }
}

.modal.warning {
  .modal-dialog {
    min-width: 600px;
    min-height: 321px;

    @include media-breakpoint-down(sm) {
      min-width: 335px;
      min-height: 468px;
    }
  }

  .modal-content {
    border-radius: $border-radius;
    width: 100%;
    height: 100%;
  }
}

.modal.update-credit-card,
.modal.review,
.modal.sold,
.modal.profile {
  $modal-width: 600px;

  .modal-dialog {
    max-width: $modal-width;
  }

  .modal-content {
    border-radius: $border-radius;
    width: $modal-width !important;
    max-width: unset;
    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }

    .modal-close {
      right: 0;
      top: 13px;
    }
  }
}

.modal.pro-modal {
  $modal-width: 625px;

  .modal-dialog {
    max-width: $modal-width;
  }

  .modal-content {
    width: $modal-width !important;
    max-width: unset;
    height: 100%;
    overflow: auto;
    @include media-breakpoint-down(sm) {
      width: 316px !important;
    }
  }
}

.modal.bump-more-info-modal {
  $modal-width: 1020px;

  height: 725px;

  @include media-breakpoint-down(md) {
    height: 100%;
  }

  .modal-dialog {
    max-width: $modal-width;
  }

  .modal-content {
    width: $modal-width !important;
    max-width: unset;
    height: 100%;
    overflow: auto;

    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
}

.modal.delivery,
.modal.bumpPayment3ds {
  .modal-dialog {
    width: 80%;
    max-width: 720px;
    height: 630px;
    align-self: center;

    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: 100% !important;
      max-height: 100% !important;
      margin: 0;
    }
  }

  .modal-content {
    max-width: inherit;
    height: inherit;
    overflow: auto;
  }
}

.modal.credit-card {
  $modal-width: 370px;

  .modal-dialog {
    max-width: $modal-width;
  }

  .modal-content {
    width: $modal-width !important;
    max-width: unset;
  }
}

.modal.preview,
.modal.category-listing,
.modal.location {
  $modal-width: 700px;

  .modal-dialog {
    max-width: $modal-width;
  }

  .modal-content {
    width: $modal-width !important;
    max-width: unset;
    overflow: hidden;
    @include media-breakpoint-down(sm) {
      width: 100% !important;
    }
  }
}

.modal.account-details {
  $modal-width: 600px;

  .modal-dialog {
    max-width: $modal-width;
  }

  .modal-content {
    width: $modal-width;
    max-width: unset;
  }
}

.modal.unsubscribe {
  $modal-width: 600px;

  .modal-dialog {
    max-width: $modal-width;
  }

  .modal-content {
    width: $modal-width !important;
    max-width: unset;
  }
}

.modal.visibility-products {
  $modal-width: 1090px;

  .modal-dialog {
    max-width: $modal-width;
  }

  .modal-content {
    width: $modal-width !important;
    max-width: unset;
    background-color: rgba($blue-grey-1, 0.95);
  }
}

.modal.buy-wallacoins,
.modal.buy-product,
.modal.reactivate,
.modal.tutorial-wallacoins,
.modal.change-store-location,
.modal.become-pro {
  .modal-dialog {
    max-width: unset;
  }

  .modal-content {
    max-width: unset;
    overflow: hidden;
  }
}

.modal.phone-request {
  $modal-width: 572px;

  .modal-body {
    border-radius: 20px;
    padding: $spacing-ms $spacing-xxl $spacing-l;
  }

  .modal-dialog,
  .modal-content {
    max-width: $modal-width;
    border-radius: $button-border-radius;
  }

  .modal-close {
    left: 18px;
    top: 18px;

    tsl-svg-icon {
      @include square(18px);
    }
  }
}

.modal.full-screen {
  $modal-width: 100%;
  $modal-height: 100%;

  .modal-dialog {
    width: $modal-width;
    height: $modal-height;
    max-height: $modal-height !important;
    margin: 0 !important;
  }

  .modal-content {
    height: auto;
    min-height: $modal-height;
    border-radius: 0;

    .modal-close {
      width: auto;
      height: auto;
      cursor: pointer;
      position: relative;
      right: unset;
      top: unset;
      z-index: unset;
      text-align: right;
    }
  }
}

.modal.modal-standard {
  .modal-dialog {
    max-width: $modal-standard-width;
  }

  .header {
    background: $walla-main;
    height: 100px;
    border-top-left-radius: $modal-border-radius;
    border-top-right-radius: $modal-border-radius;
    color: $white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .modal-close {
      top: 20px;
      right: 20px;
      @include square(13px);

      tsl-svg-icon {
        @include square(13px);

        line-height: unset;
      }
    }

    h1 {
      font-size: $font-size-l;
      font-weight: $font-weight-chunky;
    }

    p {
      opacity: 0.8;
      margin: 0;
      color: $white;
      font-size: $font-size-m;
    }
  }

  .modal-content {
    width: $modal-standard-width !important;
    max-width: unset;

    .modal-close:not(.light) {
      tsl-svg-icon ::ng-deep path {
        fill: $blue-grey-3;
      }
    }

    .image-col {
      background-color: $blue-grey-4;
      min-width: 280px;
      border-top-left-radius: $modal-border-radius;
      border-bottom-left-radius: $modal-border-radius;
      display: flex;
      justify-content: center;

      img {
        width: 200px;
        margin: 0;
      }
    }

    .modal-title {
      font-weight: $font-weight-chunky;
      font-size: $font-size-l;
      margin-bottom: $spacing-ms;

      &.fail {
        color: $warn;
      }
    }

    .modal-text {
      font-size: $font-size-m;
      color: $blue-grey-2;

      &.green {
        color: $walla-main;
      }
    }

    .link {
      color: $blue-grey-3 !important;
      margin-top: $spacing-m;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .share {
      text-align: center;
      font-size: $font-size-m;
      color: $blue-grey-3;

      .social-icons {
        display: flex;
        justify-content: center;
        margin-bottom: $spacing-ml;

        &--disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: default;
        }

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: $blue-grey-5;
          @include square(34px);

          margin: 6px;
          cursor: pointer;

          tsl-svg-icon {
            ::ng-deep path {
              fill: $blue-grey-3;
            }
          }

          &:hover {
            background-color: $color-social-background;

            tsl-svg-icon ::ng-deep path {
              fill: $color-social-fill;
            }
          }
        }
      }
    }
  }
}

.modal.modal-small {
  .modal-dialog {
    width: 100%;
    max-width: 300px;
    min-height: 280px;
  }

  .modal-content {
    height: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .modal-dialog {
    max-height: calc(100% - #{$spacing-xxl});
  }

  .modal-content {
    height: 100%;
    width: 100% !important;
    max-width: 100% !important;
    overflow: auto !important;
  }

  .modal-body {
    width: 100% !important;
  }
}

.modal.collect-qr-modal {
  .modal-dialog {
    width: 80%;
    max-width: 720px;
    height: 630px;
    align-self: center;

    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: 100% !important;
      max-height: 100% !important;
      margin: 0;
    }
  }

  .modal-content {
    max-width: inherit;
    height: inherit;
    overflow: hidden !important;
  }
}

.modal.top-up-modal {
  .modal-dialog {
    width: 80%;
    max-width: 500px;
    align-self: center;

    @include media-breakpoint-down(sm) {
      width: 100% !important;
      height: 100% !important;
      max-height: 100% !important;
      max-width: 100% !important;
      margin: 0;
    }
  }

  .modal-content {
    max-width: inherit;
    height: inherit;
    overflow: hidden !important;
  }
}

.modal.in-person-information-modal {
  .modal-dialog {
    max-width: 375px;
    align-self: center;
  }

  .modal-content {
    max-width: inherit;
    height: inherit;
    overflow: hidden !important;
  }
}
