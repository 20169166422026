@mixin hide-scroll-bars {
  /* IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
