@import 'style/common';

$form-control-height: 50px;

.form-group {
  input[type='text'],
  input[type='number'],
  input[type='tel'],
  textarea {
    display: block;
    height: $form-control-height;
    width: 100%;
    font-size: $font-size-m;
    border-color: $blue-grey-5;
    border-radius: 6px;

    &:focus {
      border-color: $walla-main !important;
      color: $blue-grey-1;
      outline: none;
      box-shadow: none;
      background-color: transparent;
    }

    &::placeholder {
      color: $blue-grey-3;
    }
  }

  textarea {
    min-height: 140px;
  }

  label {
    font-size: $font-size-m;
    color: $blue-grey-2;
  }

  .chars-counter {
    font-size: $font-size-xs;
    color: $blue-grey-3;
    float: right;
    margin-top: $spacing-xs;
    margin-bottom: $spacing-s;
  }

  &.last {
    margin-bottom: 0;
  }
}

tsl-dropdown {
  padding: 0 !important;
  border: 0 !important;

  // TODO REMOVE THIS LOGIC
  &.categories-select {
    .single .value {
      tsl-svg-icon {
        display: none;
      }
    }

    tsl-dropdown-list {
      & > div .options ul li {
        padding: 0 !important;
        border: 0;

        div {
          padding: $spacing-ms !important;
          border-top: 1px solid $blue-grey-5;
          width: 100%;
          display: flex;
          align-items: center;

          &.cat_13200,
          &.cat_21000 {
            display: none;
          }
        }
      }
    }
  }
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

.custom-radio {
  cursor: pointer;

  input {
    display: none;
  }

  [svgIcon='radio-button'] {
    @include square(18px);

    vertical-align: text-top;
    margin-right: $spacing-s;
  }

  &:not(.active) {
    [svgIcon='radio-button'] #bullet {
      display: none;
    }
  }
}

.input-radio {
  display: flex;
  align-items: center;

  label {
    display: flex;
    align-items: center;
    box-shadow: none !important;
    cursor: pointer;

    span {
      margin-left: $spacing-xs;
    }
  }

  input {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    border: 1px solid $blue-grey-4;
    appearance: none;
    position: relative;
    cursor: pointer;

    &:checked {
      border: 1px solid $walla-main;

      &::after {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $walla-main;
        border-radius: 50%;
      }
    }

    &:disabled {
      cursor: default;
      border: 1px solid $blue-grey-4;

      &::after {
        background-color: $blue-grey-4;
      }
    }
  }
}
