.modal.report {
  .scroll {
    height: 280px;
    overflow-y: auto;
    padding: $spacing-ms;
  }

  h1 {
    font-size: $font-size-l;
    color: $blue-grey-1;
    margin-bottom: $spacing-xl;
  }

  .report-ban-reason {
    position: relative;
    cursor: pointer;
    margin: 0 0 $spacing-ml;
    width: 100%;

    .ban-reason-icon-container {
      @include square(60px);

      position: relative;
      border: 1px solid $blue-grey-5;
      border-radius: 50%;
      flex-shrink: 0;
      cursor: pointer;
    }

    tsl-svg-icon {
      @include square(28px);

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      ::ng-deep g g {
        fill: $blue-grey-1;
      }
    }

    .label {
      font-size: $font-size-m;
      color: $blue-grey-2;
    }

    &:hover .ban-reason-icon-container {
      border-color: $blue-grey-4;
    }

    &.selected {
      .ban-reason-icon-container {
        border: 2px solid $walla-main;
      }

      .label {
        color: $walla-main;
        font-weight: $font-weight-chunky;
      }

      tsl-svg-icon ::ng-deep {
        g g,
        path {
          fill: $walla-main;
        }
      }
    }
  }

  .report-listing-textarea {
    width: 100%;
    padding: $spacing-ms $spacing-m;
    border: 1px solid $blue-grey-5;
    border-radius: 10px;
    min-height: 120px;
    margin-top: $spacing-ml;

    &::placeholder {
      color: $blue-grey-3;
    }
  }

  tsl-button {
    margin-top: $spacing-ml;
  }
}

.report-icon {
  svg {
    height: 100%;
    width: 100%;
  }
}
