@keyframes fade-in-from-none {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes placeholder-animation {
  0% {
    background-color: $blue-grey-5;
  }

  50% {
    background-color: $blue-grey-4;
  }

  100% {
    background-color: $blue-grey-5;
  }
}
