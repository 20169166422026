@import 'style/common';

.nav {
  &.filters-nav {
    display: inline-block;
    line-height: $line-height-xl;
    overflow-x: auto;
    overflow-y: hidden;
    @include hide-scroll-bars;

    a {
      display: inline-block;
      font-size: $font-size-xs;
      margin-right: $spacing-ml;
      cursor: pointer;
      height: 100%;
      color: $blue-grey-2;
      text-transform: uppercase;
      font-weight: $font-weight-chunky;
      padding: 0;
      white-space: nowrap;

      &:hover {
        color: darken($blue-grey-2, 10%);
      }

      &.active {
        color: $walla-main;
        border-bottom: $tab-active-border-width solid $walla-main;
      }
    }
  }
}
