$offset: 187;
$duration: 1.4s;

.spinner {
  svg {
    animation: rotator $duration linear infinite;

    .path {
      stroke-dasharray: $offset;
      stroke-dashoffset: 0;
      transform-origin: center;
      stroke: $walla-main;
      animation: dash $duration ease-in-out infinite;
    }
  }

  &.white {
    svg {
      .path {
        stroke: $white;
      }
    }
  }

  &.grey {
    @include square(14px);

    svg {
      .path {
        stroke: $blue-grey-4;
      }
    }
  }
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: $offset;
  }

  50% {
    stroke-dashoffset: $offset * 0.25;
    transform: rotate(135deg);
  }

  100% {
    stroke-dashoffset: $offset;
    transform: rotate(450deg);
  }
}
