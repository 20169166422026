// Bumps colors

$softblue-light: #c6edf6;
$city-bump: #31a0bd;
$city-bump-dark: #2f9cb9;

// Various

$map-bg: #efe8e0;
$color-favorite-icon-fill: $negative-main;
$color-inbox-background: #f4f5f7;
$color-social-background: #d8dbdd;
$color-social-fill: #a3afb5;

// Graph colors

$graph-favorites: lighten($magenta-main, 15%);
$graph-featured: darken($walla-soft, 15%);

// Pro colors

$color-pro-light: #8e6afa;
$color-pro-main: $skyblue-main;
$color-pro-discount: $magenta-main;

// Global colors
$main-background-color: rgba($blue-grey-5, 0.6);

// Checkout colors
$checkout-summary-grey: rgb(236 239 241 / 70%);
