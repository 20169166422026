.upload-page {
  $checkbox-width: 24px;
  $transition: all 0.5s ease;
  $feature-button-width: 170px;
  $custom-gutter-padding: calc($chds-size-12 / 2);

  &.container {
    @include media-breakpoint-up(md) {
      @include remove-horizontal-padding;
    }
  }

  .box,
  .main-box {
    @include upload-section-styles;

    h2 {
      @include upload-section-title-styles;
    }

    h3 {
      @include chds-typo-mid-m;

      color: $chds-color-content-high;
      font-weight: 700;
    }

    p {
      @include upload-section-subtitle-styles;
    }
  }

  .selector-title {
    font-size: $font-size-s;
    color: $blue-grey-2;
  }

  .selector-container {
    margin: 0 -#{$spacing-s};

    .selector {
      padding: 0 $spacing-s;
      margin: 0;
      max-width: 80px;
      vertical-align: top;

      .selector-icon {
        background-color: $blue-grey-5;
        border-radius: $border-radius;
        @include square(60px);

        position: relative;
        text-align: center;
        white-space: normal;
      }

      &.btn {
        border-radius: $border-radius !important;

        &:focus,
        &.focus {
          box-shadow: none;
        }

        [ngbButton] {
          position: absolute;
          clip: rect(0, 0, 0, 0);
          pointer-events: none;
        }
      }

      .selector-text {
        display: block;
        color: $blue-grey-2;
        font-weight: $font-weight-chunky;
        font-size: $font-size-xxs;
        line-height: $line-height-m;
        margin-top: $spacing-xs;
        text-align: center;

        &--uppercase {
          text-transform: uppercase;
        }
      }

      tsl-svg-icon {
        height: 48px;
        width: 48px;
        @include in-the-middle;

        ::ng-deep svg {
          g,
          path {
            fill: $blue-grey-3;
          }
        }
      }

      &--cars {
        tsl-svg-icon {
          height: 26px;
          width: 26px;
        }
      }

      &.active {
        .selector-icon {
          background-color: $walla-main;

          tsl-svg-icon {
            svg g,
            svg path {
              fill: $white !important;
            }
          }
        }

        span {
          color: $walla-main;
        }
      }

      &:hover:not(.active) {
        cursor: pointer;

        span {
          color: $blue-grey-2;
        }

        tsl-svg-icon {
          ::ng-deep {
            svg g,
            svg path {
              fill: $blue-grey-2;
            }
          }
        }
      }

      input[type='checkbox'] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
      }
      @include media-breakpoint-down(sm) {
        &.shipping-selector {
          width: calc(100% / 5);
          padding: 0;

          .selector-icon {
            background-color: $white;
          }

          &.active {
            .selector-icon {
              background-color: $white;

              tsl-svg-icon {
                svg g,
                svg path {
                  fill: $walla-main !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .btn-primary,
  .btn-link {
    padding: $spacing-m $spacing-xxl;
    margin-bottom: $spacing-xxxl;
    margin-top: $spacing-xl - $spacing-ml;
  }

  .btn-link {
    @include chds-typo-low-m-link;

    &[disabled] {
      opacity: 0.5;
    }

    &:focus {
      box-shadow: none;
    }

    &--bold {
      @include chds-typo-mid-m-link;
    }

    &--wallaGreen {
      color: $walla-dark-1;
    }
  }

  .checkbox {
    transition: $transition;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .resized & {
      width: $checkbox-width;
      opacity: 1;
    }

    span {
      color: $blue-grey-2;
      cursor: pointer;

      &.selected {
        color: $yellow-dark;
      }
    }

    .select {
      @include square($checkbox-width);

      border: 2px solid $blue-grey-4;
      border-radius: 6px;
      padding: 0;
      background: transparent;
      margin-right: $spacing-ms;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      tsl-svg-icon {
        display: none;
      }

      &.selected {
        background: $yellow-dark;
        border-color: transparent;

        tsl-svg-icon {
          display: flex;

          svg g g {
            fill: $white;
          }
        }
      }

      &:hover {
        border-color: $yellow-dark;
      }
    }

    &.checkbox-corporate {
      .select {
        &.selected {
          background: $walla-main;
        }

        &:hover {
          border-color: $walla-main;
        }
      }
    }

    input[type='checkbox'] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }

  .Upload__gutter {
    &--left {
      @include media-breakpoint-up(md) {
        padding-left: $custom-gutter-padding;
      }
    }

    &--right {
      @include media-breakpoint-up(md) {
        padding-right: $custom-gutter-padding;
      }
    }

    &--horizontal {
      padding-right: $chds-size-12;

      @include media-breakpoint-up(md) {
        padding-left: $custom-gutter-padding;
        padding-right: $custom-gutter-padding;
      }
    }

    &--bottom {
      padding-bottom: $chds-size-16;

      @include media-breakpoint-up(md) {
        padding-bottom: 0;
      }
    }
  }
}

form {
  &.ng-pending {
    input,
    textarea {
      &.ng-invalid {
        border: 1px solid $negative-main !important;
      }
    }

    tsl-dropdown.ng-invalid > .Dropdown > div {
      border: 1px solid $negative-main !important;
    }

    .DropArea__wrapper:first-of-type .DropArea__label__placeholder {
      border-color: $chds-color-negative-mid !important;

      walla-icon::part(inner-svg) {
        fill: $chds-color-negative-mid;
      }

      span {
        color: $chds-color-negative-mid;
      }
    }

    .input-radio.ng-invalid input {
      border: 1px solid $negative-main !important;
    }

    .ng-invalid {
      .error {
        color: $negative-main;
      }
    }
  }

  &.disabled {
    .form-control:disabled {
      cursor: auto;
      border: 0;
    }
  }
}
